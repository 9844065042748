.foot-item[data-v-456aea8d] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.foot-item[data-v-456aea8d] .el-button:focus,
.foot-item[data-v-456aea8d] .el-button:hover {
  color: #303133;
  border-color: #f6f7fa;
  background-color: #f6f7fa;
}
.el-button.moreBtn[data-v-456aea8d] {
  width: 100px;
}
.el-button--primary[data-v-456aea8d] {
  background-color: #0070FF !important;
}
.el-button--success[data-v-456aea8d] {
  background-color: #009A02 !important;
}
.el-button--danger[data-v-456aea8d] {
  background-color: #D6000F !important;
}
.el-button--primary.is-plain[data-v-456aea8d] {
  color: #ffffff;
  background: #FFFFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0070FF;
}
.el-button--primary.is-plain[data-v-456aea8d]:focus, .el-button--primary.is-plain[data-v-456aea8d]:hover {
  background: #DDEFFF;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #0070FF;
}